.t-toolbar{
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    flex-wrap: wrap;
    min-height: 55px;
}


.t-tool-button{
    width: auto;
    min-height: 55px;
    line-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.t-dropdowns{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.t-dropdowns-title{
    width: auto;
    min-height: 55px;
    line-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer; 
}

.t-dropdowns-ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;

}
.btn-secondary{
    background: none !important;
    border: none !important;
    color: #000 !important;
    padding: 0 5px !important;
}
.dropdown-menu{
    // top: -30px !important;
}
.tool_button_box{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}