.right_editor_animation{
    width: 100%;
}
.render_fold_editor{
    border: solid 1px red;
    width: 100%;
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.animation_btns{
    position: absolute;
    z-index: 5;
    top: 20%;
    left: 60%;
    border: solid 1px red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 600px;
    transition: left .5s;
    transition-delay: 0.5s;
    .btns_tips_modal{
        width: 100%;
        height: 100%;
        background-color: #ddd;
        transition: all .5s;
    }
    .btns_tips_btns{
        border: solid 1px blue;
    }
}