.audio_split_layout{
    position: relative;
    width: 100%;
    height: 600px;
    /* min-height: 620px;
    overflow-y: auto; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-start; */
    /* height: 240px; */
    padding: 15px;
    border-bottom: 1px solid #eeeeee;
}

.audio_split_controller{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background-color: rgba(238, 112, 45, 0.95);
    color: #e6e6e6;
    font-size: 14px;
}
.audio_split_controller div{
    flex: 1;
    text-align: center;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
}

.audio_split_segments_layout{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 55;
}

.audio_split_segments_content{
    width: 800px;
    height: 240px;
    padding: 10px 8px;
    display: flex;
    flex-direction: column;
    background-color: rgba(238, 112, 45, 1);
    overflow-y: auto;
    font-size: 14px;
}
.segmentsRowView{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.segmentsRowView div{
    text-align: center
}

.segmentsMenuView{
    position: absolute;
    top: 4px;
    right: 4px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: rgba(238, 112, 45, 1);
    color: #fff;
    line-height: 50px;
    text-align: center;
}

