.RichEditor-blockquote {
    border-left: 4px solid #ED6146;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 2px 5px;
}
.RichEditor-blockleft{
    text-align: left;
}
.RichEditor-blockright{
    text-align: right;
}
.RichEditor-blockcenter{
    text-align: center;
}