.render_search_page{
    width: 100%;
    position: relative;
    .editor_base_nav_layout{
        margin-top: 20px;
    }
    .filter_keyword_line_box{
      
    }
}
.articles_loading_more{
    width: 100%;
    height: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    .loading_black{
        margin-top: 100px;
        text-align: center;
        color: #ddd;
        img{
            width: 90px;
            display: block;
        }
    }
}
.render_list_detail{
    max-width: 500px;
    width: 100%;
    .list_detail_main{
        width: 100%;
        position: relative;
        .detail_main_close{
            width: 500px;
            position: fixed;
            top: 0;
            z-index: 100;
            height: 120px;
            border-bottom: solid 1px #DEDEDE;
            background-color: #fff;
            .close_fff{
                height: 80px;
                width: 100%;
            }
            .close_box{
                color: #333333;
                font-size: 13px;
                display: flex;
                align-items: center;
                img{
                    width: 9px;
                    margin-right: 10px;
                }
            }
        }
        // 主要内容
        .detail_main_content{
            width: 100%;
            color: #333333;
            font-size: 15px;
            font-weight: 700;
            margin-top: 55px;
            position: relative;
            .detail_title{
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 20px;
            }
            .detail_others{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #AAAAAA;
                font-size: 12px;
            }
            .detail_sunmmary{
                width: 100%;
                background:rgba(254,251,248,1);
                border-radius:2px;
                padding: 20px;
                margin-top: 20px;
                line-height: 25px;
                .summmary_title{
                    font-size:14px;
                    font-family:PingFang SC;
                    color:rgba(51,51,51,1);
                    span{
                        color:#BDBDBD;
                        font-size: 12px;
                        margin-left: 10px;
                    }
                }
                ul{
                    margin: 0;
                    padding: 0;
                }
                li{
                    list-style: disc;
                    color: #666666;
                    font-size: 12px;
                    margin-left: 15px;
                    line-height: 25px;
                }
            }
        }
        .detail_details_txts_main{
            position: relative;
        }
        .detail_details_txts{
            width: 100%;
            max-width: 500px;
            // width: 500px;
            margin-top: 10px;
            font-size:13px;
            font-family:PingFang SC;
            font-weight:bold;
            color:rgba(51,51,51,1);
            line-height: 25px;
            // position: relative;
            img{
                width: 100%;
                margin: 20px 0;
            }
        }
        
    }
    // 引用文本小弹窗
    .article_quote_modal{
        position: absolute;
        width: 90px;
        height: 40px;
        background: rgba(51,51,51,.7);
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 6px;
        font-size: 13px;
        left: 270px;
        
    }
}
.render_customer_feedback{
    // width: 300px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 7px 0px rgba(176,176,176,0.16);
    border-radius:2px;
    padding: 10px 20px;
    padding-bottom: 30px;
    .customer_feedback_close{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 20px;

    }
    .customer_feedback_main{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .feedback_main_er{
            width: 150px;
        }
        .feedback_main_content{
            width: 250px;
            margin-left: 10px;
        }
        .feedback_main_title{
            color: #333;
            font-size: 16px;
            font-weight: bold;
            padding: 12px 0;
            letter-spacing: 3px;
        }
        .feedback_main_txt{
            color: #666;
            font-size: 13px;
            line-height: 20px;
        }
    }
}