.render_animation_code{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    min-height: 100vh;
    // background: #fff;
    overflow: hidden;
    @mixin pseudo ($content: '') {
        position: absolute;
        content: $content;
    }
    #container {
        position: relative;
        transform: scale(0.725);
      }
      
      .divider {
        position: absolute;
        z-index: 2;
        top: 65px;
        left: 200px;
        width: 50px;
        height: 15px;
        background: #fff;
      }
      
      .loading-text {
        position: relative;
        font-size: 3.75rem;
        font-weight: 300;
        white-space: nowrap;
        &::before {
          // For dot
          @include pseudo;
          z-index: 1;
          top: 40px;
          left: 115px;
          width: 6px;
          height: 6px;
          background: #ED6146;
          border-radius: 50%;
          animation: dotMove 1800ms cubic-bezier(0.25,0.25,0.75,0.75) infinite;
        }
        .letter {
          display: inline-block;
          position: relative;
        //   color: #000;
          color: #ED6146;
          letter-spacing: 22px;
          &:nth-child(1) {
            // For the letter "L"
            transform-origin: 100% 70%;
            transform: scale(1, 1.275);
            &::before {
              @include pseudo;
              top: 22px;
              left: 0;
              width: 14px;
              height: 36px;
              background: #fff;
              transform-origin: 100% 0;
              animation: lineStretch 1800ms cubic-bezier(0.25,0.25,0.75,0.75) infinite;
            }
          }
          &:nth-child(5) {
            // For the letter "i"
            transform-origin: 100% 70%;
            animation: letterStretch 1800ms cubic-bezier(0.25,0.23,0.73,0.75) infinite;
            &::before {
              @include pseudo;
              top: 15px;
              left: 2px;
              width: 9px;
              height: 15px;
              background: #fff;
            }
          }
        }
      }
      
      // For the dot
      @keyframes dotMove {
          0%, 100% {
          transform: rotate(180deg) translate(-110px, -10px) rotate(-180deg);
          }
          50% {
          transform: rotate(0deg) translate(-111px, 10px) rotate(0deg);
          }
      }
      
      // For the letter "i"
      @keyframes letterStretch {
        0%, 100% {
          transform: scale(1, 0.35);
          transform-origin: 100% 75%;
        }
        8%, 28% {
          transform: scale(1, 2.125);
          transform-origin: 100% 67%;
        }
        37% {
          transform: scale(1, 0.875);
          transform-origin: 100% 75%;
        }
        46% {
          transform: scale(1, 1.03);
          transform-origin: 100% 75%;
        }
        50%, 97% {
          transform: scale(1);
          transform-origin: 100% 75%;
        }
      }
      
      // For the letter "L"
      @keyframes lineStretch {
        0%, 45%, 70%, 100% {
          transform: scaleY(0.125);
        }
        49% {
          transform: scaleY(0.75);
        }
        50% {
          transform: scaleY(0.875);
        }
        53% {
          transform: scaleY(0.5);
        }
        60% {
          transform: scaleY(0);
        }
        68% {
          transform: scaleY(0.18);
        }
      }
      
      @media(min-width: 48rem) {
        #container {
          transform: scale(0.725rem);
        }
      }
      
      @media(min-width: 62rem) {
        #container {
          transform: scale(0.85);
        }
      }
}
.render_animation_loader{
  .loader {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: #ED6146;
    letter-spacing: 0.2em;
    
    &::before, &::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: #ED6146;
      position: absolute;
      animation: load .7s infinite alternate ease-in-out;
    }
    
    &::before {
      top: 0;
    }
    
    &::after {
      bottom: 0;
    }
  }
  
  @keyframes load {
    0% { left: 0; height: 30px; width: 15px }
    50% { height: 8px; width: 40px }
    100% { left: 235px; height: 30px; width: 15px}
  }
}