.render_audio_split{
    width: 100%;
    height: 100%;
    position: relative;
    .ai_page_main{
        width: 100%;
        height: 100%;
        .audio_layout_content{
            height: 600px;
            overflow: hidden;
            overflow-y: auto;
        }
        /*滚动条整体样式*/
        .audio_layout_content::-webkit-scrollbar {
            width: 0px;
            display: none;
        }
        /*滚动条滑块*/
        .audio_layout_content::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: #BEBEBD;
        }
        /*滚动条轨道*/
        .audio_layout_content::-webkit-scrollbar-track {
            border-radius: 20px;
            background: #F5F4F3;
        }
    }
}
.AI_page{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .ai_page_topbar{
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
    .ai_page_audio{
        width: 1300px;
        // margin-top: 100px;
        // height: 700px;
        display: flex;
        justify-content: flex-start;
        
    }
}
.render_split_one{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: flex-start;
    .audio_left{
        position: relative;
        width: 350px;
        height: 100%;
        background:rgba(255,255,255,1);
        box-shadow:0px 2px 4px 0px rgba(11,11,11,0.4);
        .left_title{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #ED6146;
            font-size: 14px;
            padding: 20px;
        }
        .left_content{
            height: 450px;
            overflow-y: auto;
            .audio_list_content{
                padding: 10px 0;
                border-bottom: solid 1px #E9E9E9;
                text-align: center;
                font-size: 14px;
            }
            .left_content_empty{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ED6146;
                font-size: 14px;
            }
        }
        .left_synthesis_btn{
            position: absolute;
            bottom: 30px;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .synthesis_btn{
                background:linear-gradient(0deg,rgba(237,97,70,1) 0%,rgba(232,123,102,1) 100%);
                border-radius:2px;
                color: #fff;
                font-size: 16px;
                width: 100px;
                height: 40px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
    .audio_right{
        // border: solid 1px red;
        width: 100%;
        .one_audio_empty{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ED6146;
            font-size: 14px;
        }
    }
}